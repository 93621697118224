var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campus-health"},[_c('heads'),_c('img',{staticClass:"top-bg",attrs:{"src":require("../../assets/images/campus_health_top_bg.png"),"alt":""}}),_c('div',{staticClass:"campus-health-container"},[(
                _vm.articleCategory.name != null && _vm.articleCategory.name != ''
            )?_c('div',{staticClass:"content"},[_c('div',{staticClass:"introduce"},[_c('p',[_vm._v(_vm._s(_vm.articleCategory.description))])]),_c('div',{staticClass:"display-or-list",style:({
                    backgroundImage:
                        'url(' + _vm.articleCategory.video_background + ')',
                })},[_c('div',{staticClass:"video"},[_c('div',{staticClass:"video-box"},[(_vm.options.vid != '')?_c('vue-aliplayer-v2',{ref:"VueAliplayerV2",attrs:{"options":_vm.options}}):_vm._e()],1),_c('div',{staticClass:"division"},[_c('div',{staticClass:"course-name"},[_vm._v(" "+_vm._s(_vm.video.name .replace("︽", "《") .replace("︾", "》"))+" ")]),_c('div',{staticClass:"nice-scroll"},_vm._l((_vm.video.course_division_category),function(item,index){return _c('div',{key:index},_vm._l((item.course_division),function(items,indexs){return _c('div',{key:indexs,staticClass:"course-diviosn",attrs:{"id":_vm.video.id,"division_id":items.division_content_id},on:{"click":function($event){return _vm.replayDivisionCourse(
                                            _vm.video.id,
                                            items.division_content_id,
                                            items.charge_mode_id,
                                            items.course_vid
                                        )}}},[_c('p',{class:_vm.isActive ==
                                                items.division_content_id ||
                                            (indexs == 0 &&
                                                _vm.queryWhere.division_id == 0)
                                                ? 'division-active'
                                                : 'division'},[_vm._v(" "+_vm._s(items.name)+" ")])])}),0)}),0)])])]),_c('div',{staticClass:"category-navs"},_vm._l((_vm.videoList),function(item,index){return _c('div',{staticClass:"category-navs-item"},[_c('router-link',{attrs:{"to":{
                            path: '/campusHealth/video/detail',
                            query: { id: item.id },
                        }}},[_c('span',{staticClass:"title",class:item.id == 12
                                    ? 'font-size-18'
                                    : item.id == 312
                                    ? 'font-size-20'
                                    : item.id == _vm.queryWhere.id
                                    ? 'course-activity'
                                    : ''},[_vm._v(_vm._s(item.name .replace("︽", "《") .replace("︾", "》")))])])],1)}),0)]):_vm._e()]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }