<template>
    <div class="campus-health">
        <heads></heads>
        <img
            class="top-bg"
            src="../../assets/images/campus_health_top_bg.png"
            alt=""
        />
        <div class="campus-health-container">
            <div
                class="content"
                v-if="
                    articleCategory.name != null && articleCategory.name != ''
                "
            >
                <div class="introduce">
                    <p>{{ articleCategory.description }}</p>
                </div>
                <div
                    class="display-or-list"
                    :style="{
                        backgroundImage:
                            'url(' + articleCategory.video_background + ')',
                    }"
                >
                    <div class="video">
                        <div class="video-box">
                            <vue-aliplayer-v2
                                v-if="options.vid != ''"
                                ref="VueAliplayerV2"
                                :options="options"
                            />
                        </div>
                        <div class="division">
                            <div class="course-name">
                                {{
                                    video.name
                                        .replace("︽", "《")
                                        .replace("︾", "》")
                                }}
                            </div>
                            <div class="nice-scroll">
                                <div
                                    v-for="(
                                        item, index
                                    ) in video.course_division_category"
                                    :key="index"
                                >
                                    <div
                                        class="course-diviosn"
                                        v-for="(
                                            items, indexs
                                        ) in item.course_division"
                                        :key="indexs"
                                        @click="
                                            replayDivisionCourse(
                                                video.id,
                                                items.division_content_id,
                                                items.charge_mode_id,
                                                items.course_vid
                                            )
                                        "
                                        :id="video.id"
                                        :division_id="items.division_content_id"
                                    >
                                        <p
                                            :class="
                                                isActive ==
                                                    items.division_content_id ||
                                                (indexs == 0 &&
                                                    queryWhere.division_id == 0)
                                                    ? 'division-active'
                                                    : 'division'
                                            "
                                        >
                                            {{ items.name }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="category-navs">
                    <div
                        class="category-navs-item"
                        v-for="(item, index) in videoList"
                    >
                        <router-link
                            :to="{
                                path: '/campusHealth/video/detail',
                                query: { id: item.id },
                            }"
                        >
                            <span
                                class="title"
                                :class="
                                    item.id == 12
                                        ? 'font-size-18'
                                        : item.id == 312
                                        ? 'font-size-20'
                                        : item.id == queryWhere.id
                                        ? 'course-activity'
                                        : ''
                                "
                                >{{
                                    item.name
                                        .replace("︽", "《")
                                        .replace("︾", "》")
                                }}</span
                            >
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import $store from "../../store";
import { scroll } from "@utils/scroll";
import foot from "@components/foot.vue";
import heads from "@components/head.vue";
import VueAliplayerV2 from "vue-aliplayer-v2";
import {
    getArticleCategory,
    getVideoList,
    getEncryptionVideo,
    getVideoPlayAuth,
} from "@api/public";
export default {
    components: {
        heads,
        foot,
        VueAliplayerV2,
    },
    data() {
        return {
            video: {},
            queryWhere: {},
            videoList: [],
            articleCategory: { children: [] },
            options: {
                autoplay: false,
                height: "100%",
                playsinline: true,
                vid: "",
                playauth: "",
                cover: "",
                encryptType: 1,
            },
            isActive: false,
        };
    },
    watch: {
        $route(to, from) {
            this.$router.go(0);
        },
    },
    mounted: function () {
        let that = this;
        this.getEncryptionVideo();
        getArticleCategory({ id: 12 }).then(function (res) {
            that.$set(that, "articleCategory", res.data);
        });
        getVideoList({ course_cate_id: 38, limit: 30 }).then(function (res) {
            let videoListTemp = res.data.list.map((item) => {
                return that.handleCourseName(item);
            });
            that.$set(that, "videoList", videoListTemp);
        });
    },
    methods: {
        getEncryptionVideo: function () {
            let that = this;
            getEncryptionVideo(that.queryWhere).then((res) => {
                let videoTemp = that.handleCourseName(res.data);
                that.$set(that, "video", videoTemp);
                that.$set(that.options, "vid", res.data.course_vid);
                that.$set(that.options, "playauth", res.data.play_auth);
                if (res.data.copy != "") {
                    that.$set(that.options, "cover", res.data.copy);
                }
            });
            scroll($(".nice-scroll"));
        },
        handleCourseName: function (datas) {
            if (datas.name.indexOf("《") == -1) {
                datas.name = "《" + datas.name;
            }
            if (datas.name.lastIndexOf("》") == -1) {
                datas.name = datas.name + "》";
            }
            return datas;
        },
        replayDivisionCourse: function (
            id,
            division_id,
            charge_mode,
            courseVid
        ) {
            if (
                charge_mode == 1 ||
                ($store.state.app.userInfo != null &&
                    $store.state.app.userInfo.is_empower)
            ) {
                this.queryWhere.id = id;
                this.queryWhere.division_id = division_id;
                this.isActive = division_id;
                this.options.autoplay = true;
                getVideoPlayAuth({ course_vid: courseVid, region: 1 }).then(
                    (res) => {
                        this.options.vid = courseVid;
                        this.options.playauth = res.data.videoPlayAuth.playAuth;
                        if (this.options.vid != "") {
                            this.replayByVidAndPlayAuth(
                                this.options.vid,
                                this.options.playauth
                            );
                            this.play();
                        }
                    }
                );
            } else {
                if (!$store.state.app.token) {
                    layui.use(["layer"], function () {
                        var layer = layui.layer;
                        layer.open({
                            title: "",
                            content: "您还未登录！是否去登录？",
                            btn: ["是", "否"],
                            closeBtn: 0,
                            skin: "layui-layer-molv",
                            yes: function () {
                                window.location.href = "/login";
                            },
                        });
                    });
                } else if (!$store.state.app.userInfo.is_empower) {
                    layui.use(["layer"], function () {
                        var layer = layui.layer;
                        layer.open({
                            title: "",
                            content:
                                "该部分内容为课题实验校、示范区会员账号专属内容，如需加入课题实验，请点击下方“课题申报”链接，按照要求提交信息，经课题组审核批准后，方可成为课题实验校、示范区，获得专属课题账号，正常查看全部内容。",
                            closeBtn: 0,
                            btn: ["课题申报", "关闭"],
                            success: function (layero) {
                                var btn = layero.find(".layui-layer-btn");
                                btn.find(".layui-layer-btn0").attr({
                                    href: "/application/shenbaobiao",
                                });
                            },
                        });
                    });
                }
            }
        },
        replayByVidAndPlayAuth(vid, playauth) {
            this.$refs.VueAliplayerV2.replayByVidAndPlayAuth(vid, playauth);
        },
        play() {
            this.$refs.VueAliplayerV2.play();
        },
    },
    created: function () {
        this.queryWhere = this.$route.query;
        this.queryWhere.division_id = 0;
        document.title = this.$route.meta.title;
    },
};
</script>

<style scoped>
.campus-health {
    position: relative;
    width: 100%;
    height: auto;
    background-color: #3a657d;
}

.campus-health .top-bg {
    width: 1920px;
    height: 470px;
}

.campus-health .campus-health-container {
    position: relative;
    width: 1920px;
    height: auto;
    top: -205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health .campus-health-container .content {
    position: relative;
    width: 960px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.campus-health .campus-health-container .content .introduce {
    position: relative;
    width: 100%;
    height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health .campus-health-container .content .introduce p {
    position: relative;
    height: auto;
    max-height: 205px;
    font-size: 26px;
    overflow-y: auto;
    font-family: kaiti;
    text-indent: 2em;
    color: white;
    word-break: break-all;
    text-align: justify;
    margin-left: 70px;
    margin-right: 76px;
}

.campus-health .campus-health-container .content .display-or-list {
    position: relative;
    width: 100%;
    height: 540px;
    background-size: 960px 540px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.campus-health .campus-health-container .content .display-or-list .video {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    margin: 30px;
    width: 100%;
    /* border: 1px solid red; */
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .video
    .video-box {
    position: relative;
    width: 600px;
    height: 454px;
    background: url(../../assets/images/campus_health_video_play_border.jpg)
        600px 454px no-repeat;
    background-size: cover;
    background-position: center 0;
    /* border: 1px solid blue; */
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .video
    .video-box
    .prism-player {
    position: relative;
    left: 35px;
    top: 35px;
    height: 84% !important;
    width: 88% !important;
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .video
    > .division {
    width: 280px;
    position: relative;
    height: 454px;
    /* border: 1px solid yellow; */
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .video
    > .division
    .course-name {
    position: relative;
    font-size: 26px;
    font-family: kaiti;
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .video
    > .division
    .nice-scroll
    p {
    position: relative;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .video
    .nice-scroll
    .course-diviosn
    + .course-diviosn {
    margin-top: 10px;
}

.campus-health .campus-health-container .content .category-navs {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
}

.campus-health .campus-health-container .content .display-or-list .school-list {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 30px;
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .school-list.column-gap-100 {
    width: 80%;
    column-gap: 100px;
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .school-list.column-gap-120 {
    width: 60%;
    column-gap: 120px;
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .school-list
    .school-list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .school-list
    .school-list-item
    img {
    position: relative;
    width: 110px;
    height: 110px;
    border-radius: 10px;
}

.campus-health
    .campus-health-container
    .content
    .display-or-list
    .school-list
    .school-list-item
    .title {
    position: relative;
    font-family: kaiti;
    font-weight: bold;
    font-size: 22px;
    margin-top: 15px;
}

.campus-health
    .campus-health-container
    .content
    .category-navs
    .category-navs-item {
    position: relative;
    width: 153px;
    height: 43px;
    background: url(../../assets/images/campus_health_category_title_bg.png)
        153px 43px no-repeat;
    background-size: cover;
    background-position: center 0;
}

.campus-health
    .campus-health-container
    .content
    .category-navs
    .category-navs-item
    a {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campus-health
    .campus-health-container
    .content
    .category-navs
    .category-navs-item
    a
    .title {
    position: relative;
    text-align: center;
    vertical-align: middle;
    color: #2a4f47;
    font-family: kaiti;
    font-size: 22px;
    font-weight: bold;
}

.campus-health
    .campus-health-container
    .content
    .category-navs
    .category-navs-item
    a
    .title.font-size-18 {
    font-size: 18px !important;
}

.campus-health
    .campus-health-container
    .content
    .category-navs
    .category-navs-item
    a
    .title.font-size-20 {
    font-size: 20px !important;
}

.nice-scroll {
    overflow-y: auto;
    height: 90%;
}

p.division-active {
    color: #c3a769 !important;
}

p.division {
    color: #000 !important;
    width: 100%;
}

.course-activity {
    color: #900c0c !important;
}

/* 设置滚动条样式 */
/* 设置滚动条的宽度和高度 */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* 设置滚动条轨道的背景色 */
::-webkit-scrollbar-track {
    /* background: #c2c2c2; */
    background: transparent;
}

/* 设置滚动条滑块的颜色 */
::-webkit-scrollbar-thumb {
    background: #727171;
    border-radius: 10px;
}

/* 设置滚动条滑块悬停时的颜色 */
::-webkit-scrollbar-thumb:hover {
    background: #505050;
}
</style>
